import axios from "axios";
import AuthService from "@/services/auth.service";

class Service {

  constructor() {
    this.setupBaseURL()
    this.setupAuthorizationHeader()
  }

  setupBaseURL() {
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  }

  setupAuthorizationHeader() {
    axios.interceptors.request.use(config => {
      const token = AuthService.getToken();

      if(token) config.headers.Authorization = `Bearer ${token}`;

      return config;
    }, err => {
      return Promise.reject(err);
    });
  }
}

export default new Service();
