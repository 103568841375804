<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol sm="5">
          <h4 id="traffic" class="card-title mb-0">{{ $tc('ride', 2) }}</h4>
          <div class="small text-muted">{{ $t('all_day_long') }}</div>
        </CCol>
        <CCol sm="7">
          <SwitchServiceType
            v-on:change="getData"
          />
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="3" md="6" lg="6">
          <CCallout color="info">
            <small class="text-muted">{{ this.$t('asked') }}</small><br>
            <strong class="h4">{{ this.today.order_nb | formatNumber }} </strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
            <strong class="h6">{{ this.lastWeek.order_nb | formatNumber }} -- {{ this.yesterday.order_nb | formatNumber }}</strong>
          </CCallout>
        </CCol>
        <CCol sm="3" md="6" lg="6">
          <CCallout color="warning">
            <small class="text-muted">{{ this.$t('attributed') }}</small><br>
            <strong class="h4">{{ this.today.attribute_order_nb | formatNumber }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
            <strong class="h6">{{ this.lastWeek.attribute_order_nb | formatNumber }} -- {{ this.yesterday.attribute_order_nb | formatNumber }}</strong>
          </CCallout>
        </CCol>
        <CCol sm="3" md="6" lg="6">
          <CCallout color="success">
            <small class="text-muted">{{ this.$t('success') }}</small><br>
            <strong class="h4">{{ this.today.success_order_nb | formatNumber }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
            <strong class="h6">{{ this.lastWeek.success_order_nb | formatNumber }} -- {{ this.yesterday.success_order_nb | formatNumber }}</strong>
          </CCallout>
        </CCol>
        <CCol sm="3" md="6" lg="6">
          <CCallout color="danger">
            <small class="text-muted">{{ this.$t('cancel') }}</small><br>
            <strong class="h4">{{ this.today.cancel_order_nb | formatNumber }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
            <strong class="h6">{{ this.lastWeek.cancel_order_nb | formatNumber}} -- {{ this.yesterday.cancel_order_nb  | formatNumber}}</strong>
          </CCallout>
        </CCol>
        <CCol sm="3" md="6" lg="6">
          <CCallout color="dark">
            <small class="text-muted">{{ this.$t('upgraded') }}<sup>1</sup></small><br>
            <strong class="h4">{{ this.today.upgraded_order_nb | formatNumber }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
            <strong class="h6">{{ this.lastWeek.upgraded_order_nb | formatNumber}} -- {{ this.yesterday.upgraded_order_nb  | formatNumber}}</strong>
          </CCallout>
        </CCol>
      </CRow>
      <p>1 - {{ $t('on_cancelled_and_completed_rides') }}</p>
    </CCardBody>
    <CCardHeader>
      <div class="small text-muted">{{ $t('historical') }}</div>
    </CCardHeader>
    <CCardBody>
      <CChartLine
        :datasets="this.chart.datasets"
        :labels="this.chart.labels"
        :options="this.chart.options"
      />
    </CCardBody>

    <slot name="loading" v-if="isLoading">
      <CElementCover
        :boundaries="[
            { sides: ['top', 'bottom'] }
          ]"
      />
    </slot>
  </CCard>
</template>

<script>
import {CChartLine} from "@coreui/vue-chartjs";
import SwitchServiceType from "@/views/components/SwitchServiceType";
import _c from "lodash/collection";
import axios from "axios";
import Utils from "@/utils/utils";

export default {
  name: "CCOrderNumber",
  props: {
    biz: {
      type: Number
    },
    serviceType: {
      type: Number
    },
  },
  components: { CChartLine, SwitchServiceType },
  data() {
    return {
      isLoading: false,

      data: [],
      today: {},
      yesterday: {},
      lastWeek: {},
      chart: {
        datasets: [],
        labels: [],
        options: {
          tooltips: {
            mode: 'index',
            intersect: false
          },
          elements: {
            line: {
              borderWidth: 2,
              fill: false
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        }
      },
    }
  },
  created() {
    this.currentDate = new Date();
    this.currentTime = this.currentDate.toTimeString().split(' ')[0];

    this.getData()
  },
  methods: {
    getData(serviceType) {
      this.isLoading = true

      let params = {}

      if (this.biz) params.biz = this.biz;
      if (serviceType && serviceType.value !== 0) params.serviceType = serviceType.value;
      if (this.serviceType && this.serviceType !== 0) params.serviceType = this.serviceType;

      axios.get(`/orders/history`, {
        params: {
          startDate: Utils.getNthDateSimple(-8),
          endDate: Utils.getNthDateSimple(0),
          endTime: `T${this.currentTime}`,
          ...params
        }
      }).then((response) => {
        this.isLoading = false
        const data = response.data;

        if (data.length) {
          this.today = data[data.length-1] ?? data[data.length-1];
          this.yesterday = data[data.length-2] ?? data[data.length-2];
          this.lastWeek = data[1] ?? data[1];
        }
      })

      axios.get(`/orders/history`, {
        params: {
          startDate: Utils.getNthDateSimple(-8),
          endDate: Utils.getNthDateSimple(0),
          ...params
        }
      }).then((response) => {
        this.updateChart(response.data);
      })
    },

    updateChart(data) {
      let filters = this.$options.filters
      this.chart.labels = _c.map(data, (day) => filters.getShortDayStringForDate(day["statistics_date"]).capitalize())
      this.chart.datasets = [];

      let orderDataset = {
        label: this.$t('asked'),
        lineTension: 0.2,
        borderColor: 'rgba(51,148,245,0.9)',
        data: _c.map(data, (day) => day["order_nb"]),
      }

      let attributeOrderDataset = {
        label: this.$t('attributed'),
        lineTension: 0.2,
        borderColor: 'rgba(239,171,23,0.9)',
        data: _c.map(data, (day) => day["attribute_order_nb"])
      }

      let successOrderDataset = {
        label: this.$t('success'),
        lineTension: 0.2,
        borderColor: 'rgba(46,175,88,0.9)',
        data: _c.map(data, (day) => day["success_order_nb"])
      }

      let cancelOrderDataset = {
        label: this.$t('cancel'),
        lineTension: 0.2,
        borderColor: 'rgba(245,65,54,0.9)',
        data: _c.map(data, (day) => day["cancel_order_nb"]),
      }

      let upgradedOrderDataset = {
        label: this.$t('upgraded'),
        lineTension: 0.2,
        borderColor: 'rgb(54,65,53)',
        data: _c.map(data, (day) => day["upgraded_order_nb"]),
      }

      this.chart.datasets.push(orderDataset)
      this.chart.datasets.push(attributeOrderDataset)
      this.chart.datasets.push(successOrderDataset)
      this.chart.datasets.push(cancelOrderDataset)
      this.chart.datasets.push(upgradedOrderDataset)
    },
  }
}
</script>

<style scoped>

</style>
