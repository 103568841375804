<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol sm="5">
          <h4 id="drivers" class="card-title mb-0">{{ $tc('driver', 2) }}</h4>
          <div class="small text-muted">{{ $t('ride_cancel_by_driver_nb') }}</div>
        </CCol>
        <CCol sm="7">
          <SwitchServiceType
            v-on:change="getData"
          />
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        class="mb-0 table-outline"
        column-filter
        :items="orderCanceledByDriver"
        :fields="orderCanceledByDriverFields"
        hover
        sorter
        clickableRows
        @row-clicked="driverRowClicked"
      >
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import SwitchServiceType from "@/views/components/SwitchServiceType";
import axios from "axios";
import Utils from "@/utils/utils";

export default {
  name: "CCDriversCancelNumber",
  props: {
    biz: {
      type: Number
    },
    serviceType: {
      type: Number
    },
  },
  components: { SwitchServiceType },
  data() {
    return {
      orderCanceledByDriver: [],
      orderCanceledByDriverFields: [
        { key: 'fullname', label: this.$t('name'), _style: 'width:150px' },
        { key: 'cancel_nb', label: this.$tc('cancel_nb', 2), _classes: "text-center", _style: 'width:100px', filter: false }
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData(serviceType) {
      let params = {}

      if (serviceType && serviceType.value !== 0) params.serviceType = serviceType.value;
      if (this.serviceType && this.serviceType !== 0) params.serviceType = this.serviceType;

      axios.get(`/drivers/orderCancelHistory`, {
        params: {
          startDate: Utils.getNthDateSimple(),
          endDate: Utils.getNthDateSimple(1),
          ...params
        }
      }).then((response) => {
        this.orderCanceledByDriver = response.data ?? response.data;
      })
    },
  }
}
</script>

<style scoped>

</style>
