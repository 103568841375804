<template>
  <CButtonGroup class="float-right mr-3">
    <CButton
      v-for="(serviceType, i) in servicesType"
      color="outline-secondary"
      class="mx-0"
      :key="`serviceType-${i}`"
      :pressed="serviceType.value === selected"
      :value="serviceType.value"
      @click="click(serviceType)"
    >
      {{serviceType.label}}
    </CButton>
  </CButtonGroup>
</template>

<script>
import {OrderEnum} from "@caocao-mobility/ccmp-enum"

export default {
  name: "SwitchServiceType",
  model: {
    event: 'change'
  },
  data() {
    return {
      selected: 0,
      servicesType: [
        {value: 0, label: 'All'},
        {value: OrderEnum.ServiceType.NEW_ENERGY, label: 'Affiliated'},
        {value: OrderEnum.ServiceType.COMFORT, label: 'TX'}
      ]
    }
  },
  methods: {
    click(serviceType) {
      this.selected = serviceType.value
      this.$emit('change', serviceType)
    }
  }
}
</script>

<style scoped>

</style>
