import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';
import Service from './service.js';

class AuthService {

  constructor() {
    Service.setupBaseURL();

    this.user = {}
    this.user.authenticated = false
    this.user.tokenExpirationDate = null
  }

  login(formData) {
    return axios
      .post(`/authenticate`, formData)
      .then(response => {

        if (response.data.token) {
          this.checkAuth();
          localStorage.setItem('token', response.data.token);
        }

        return response;
      });
  }

  logout() {
    localStorage.removeItem('token');
    this.user = {}
    this.user.authenticated = false;
    this.user.tokenExpirationDate = null

    document.location.reload();
  }

  checkAuth() {
    let token = localStorage.getItem('token');
    if (!token) return this.user = {}

    let tokenPayload = jsonwebtoken.decode(token)
    let currentDate = new Date();

    this.user.authenticated = (tokenPayload.exp * 1000) > currentDate.getTime();

    this.user.id = tokenPayload.id
    this.user.type = tokenPayload.type
    this.user.tokenExpirationDate = tokenPayload.exp

    return this.user
  }

  userType() {
    return this.user.type;
  }

  isAuthenticated() {
    return this.user.authenticated;
  }

  getToken() {
    let token = localStorage.getItem('token')
    return token ?? token
  }
}

export default new AuthService();
