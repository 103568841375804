import Vue from 'vue'
import Router from 'vue-router'
import AuthService from '../services/auth.service.js'
import Today from "@/views/pages/Today";
import B2B from "@/views/pages/today/B2B";
import B2C from "@/views/pages/today/B2C";
import Global from "@/views/pages/Global";
import {AuthEnum} from "@caocao-mobility/ccmp-enum"
import _c from 'lodash/collection'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

let Role = {
  CaocaoUser: [
    AuthEnum.Type.CEEU_CENTER_USER,
    AuthEnum.Type.ZONE_ADMIN_USER,
    AuthEnum.Type.ZONE_USER
  ],
  AllUser: _c.map(AuthEnum.Type)
}

const router  = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to, from, next) => {
  const {authorize, redirect}  = to.meta;

  if (to.name !== 'Login') AuthService.checkAuth();
  if (to.name !== 'Login' && !AuthService.isAuthenticated()) next({name: 'Login'})

  if (authorize) {
    if (authorize.length && !authorize.includes(AuthService.userType())) {
      const redirectForUser = _c.find(redirect, (r) => { return r.authType === AuthService.userType() });

      if (redirectForUser) {
        next(redirectForUser.next)
      } else {
        throw new Error(`You Must provide a redirection path for: ${to.name} with authType: ${AuthService.userType()}`)
      }
    }
  }

  next();
})

export default router

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/global',
      component: TheContainer,
      children: [
        {
          path: 'b2b',
          name: 'B2B',
          component: B2B,
          meta: {
            label: 'nav.today.label',
            authorize: Role.CaocaoUser,
            redirect: [
              {
                authType: AuthEnum.Type.LEASER_USER,
                next: {name: 'Operation'}
              }
            ]
          }
        },
        {
          path: 'b2c',
          name: 'B2C',
          component: B2C,
          meta: {
            label: 'nav.today.label',
            authorize: Role.CaocaoUser,
            redirect: [
              {
                authType: AuthEnum.Type.LEASER_USER,
                next: {name: 'Operation'}
              }
            ]
          }
        },
        {
          path: 'global',
          name: 'Global',
          component: Global,
          meta: {
            label: 'nav.global',
            authorize: Role.CaocaoUser,
            redirect: [
              {
                authType: AuthEnum.Type.LEASER_USER,
                next: {name: 'Operation'}
              }
            ]
          }
        },
        {
          path: 'operation',
          redirect: '/operation/subcontractors',
          name: 'Operation',
          meta: {
            label: 'nav.operation.label'
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'subcontractors',
              name: 'Subcontractors',
              redirect: 'subcontractors',
              meta: {
                label: 'nav.operation.subcontractors'
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: '',
                  component: () => import(/* webpackChunkName: "group-operation" */ '../views/pages/operation/Subcontractors.vue'),
                },
                {
                  path: ':subcontractorId',
                  name: 'Subcontractor',
                  redirect: ':subcontractorId',
                  meta: {
                    label: 'nav.operation.subcontractor'
                  },
                  component: {
                    render (c) { return c('router-view') }
                  },
                  children: [
                    {
                      path: '',
                      component: () => import(/* webpackChunkName: "group-operation" */ '../views/pages/operation/Subcontractor.vue')
                    },
                    {
                      path: 'drivers/:driverId',
                      name: 'Driver',
                      meta: {
                        label: 'nav.operation.driver'
                      },
                      component: () => import(/* webpackChunkName: "group-operation" */ '../views/pages/operation/Driver.vue')
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if (AuthService.isAuthenticated()) next('/')
        else next()
      }
    }
  ]
}

