<template>
  <div>
    <CRow>
      <CCol col="12" md="6">
        <CCOrderAmount
          :biz="15"
        />
      </CCol>
      <CCol col="12" md="6">

        <CCLaboursDriversOnlineStatus/>

        <CCOrderNumber
          :biz="15"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CCOrderAmount from "@/views/components/cards/CCOrderAmount";
import CCOrderNumber from "@/views/components/cards/CCOrderNumber";
import CCLaboursDriversOnlineStatus from "@/views/components/cards/CCLaboursDriversOnlineStatus";

export default {
  name: "B2B",
  components: { CCOrderAmount, CCOrderNumber, CCLaboursDriversOnlineStatus },
  created() {

  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
