<template>
  <div>
    <CRow>
      <CCol col="12" md="5">
        <CCard>
          <CCardHeader>
            <h4 id="rider" class="card-title mb-0">{{ $tc('riders', 2) }}</h4>
            <div class="small text-muted">{{ $t('all_day_long') }}</div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6" md="6" lg="6">
                <CCallout color="success">
                  <small class="text-muted">{{ $t('nb_registration') }}</small><br>
                  <strong class="h4">{{ this.newCustomersNbToday }}</strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.newCustomersNbLastWeek }} -- {{ this.newCustomersNbYesterday }}</strong>
                </CCallout>
              </CCol>
              <CCol sm="6" md="6" lg="6">
                <CCallout color="success">
                  <small class="text-muted">{{ $t('nb_registration_with_credit_card') }}</small><br>
                  <strong class="h4">{{ this.newAccountNbToday }}</strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.newAccountNbLastWeek }} -- {{ this.newAccountNbYesterday }}</strong>
                </CCallout>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="5">
                <h4 id="gmv" class="card-title mb-0">GMV</h4>
                <div class="small text-muted">{{ $t('all_day_long') }}</div>
              </CCol>
              <CCol sm="7">
                <SwitchServiceType
                  v-on:change="updateOrderBlock"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CCallout color="info">
              <small class="text-muted">{{ $t('gmv_estimated') }}<sup>1</sup></small><br>
              <strong class="h4">{{ this.estimateFee | convertCentsToEurosWithCurrency }} </strong><br>
              <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
              <strong class="h6">{{ this.estimateFeeLastWeek | convertCentsToEurosWithCurrency }} -- {{ this.estimateFeeYesterday | convertCentsToEurosWithCurrency }}</strong>
            </CCallout>
            <CCallout color="warning">
              <small class="text-muted">{{ $t('gmv_total') }}<sup>1</sup></small><br>
              <strong class="h4">{{ this.totalFee | convertCentsToEurosWithCurrency }}</strong><br>
              <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
              <strong class="h6">{{ this.totalFeeLastWeek | convertCentsToEurosWithCurrency }} -- {{ this.totalFeeYesterday | convertCentsToEurosWithCurrency }}</strong>
            </CCallout>
            <CCallout color="success">
              <small class="text-muted">{{ $t('gmv_net') }}<sup>1</sup></small><br>
              <strong class="h4">{{ this.realPayAmount | convertCentsToEurosWithCurrency }}</strong><br>
              <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
              <strong class="h6">{{ this.realPayAmountLastWeek | convertCentsToEurosWithCurrency }} -- {{ this.realPayAmountYesterday | convertCentsToEurosWithCurrency }}</strong>
            </CCallout>

            <p>1 - {{ $t('on_cancelled_and_completed_rides') }}</p>
            <p>2 - {{ $t('at_the_same_time_for_yesterday_or_lastweek') }}</p>

            <CChartLine
              :datasets="this.GMVChart.datasets"
              :labels="this.GMVChart.labels"
              :options="this.GMVChart.options"
            />
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol sm="5">
                <h4 id="traffic" class="card-title mb-0">{{ $tc('ride', 2) }}</h4>
                <div class="small text-muted">{{ $t('all_day_long') }}</div>
              </CCol>
              <CCol sm="7">
                <SwitchServiceType
                  v-on:change="updateOrderBlock"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="3" md="6" lg="6">
                <CCallout color="info">
                  <small class="text-muted">{{ this.$t('asked') }}</small><br>
                  <strong class="h4">{{ this.askRideNb | formatNumber }} </strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.askRideNbLastWeek | formatNumber }} -- {{ this.askRideNbYesterday | formatNumber }}</strong>
                </CCallout>
              </CCol>
              <CCol sm="3" md="6" lg="6">
                <CCallout color="warning">
                  <small class="text-muted">{{ this.$t('attributed') }}</small><br>
                  <strong class="h4">{{ this.attributeRideNb | formatNumber }}</strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.attributeRideNbLastWeek | formatNumber }} -- {{ this.attributeRideNbYesterday | formatNumber }}</strong>
                </CCallout>
              </CCol>
              <CCol sm="3" md="6" lg="6">
                <CCallout color="success">
                  <small class="text-muted">{{ this.$t('success') }}</small><br>
                  <strong class="h4">{{ this.successRideNb | formatNumber }}</strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.successRideNbLastWeek | formatNumber }} -- {{ this.successRideNbYesterday | formatNumber }}</strong>
                </CCallout>
              </CCol>
              <CCol sm="3" md="6" lg="6">
                <CCallout color="danger">
                  <small class="text-muted">{{ this.$t('cancel') }}</small><br>
                  <strong class="h4">{{ this.cancelRideNb | formatNumber }}</strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.cancelRideNbLastWeek | formatNumber}} -- {{ this.cancelRideNbYesterday  | formatNumber}}</strong>
                </CCallout>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardHeader>
            <div class="small text-muted">{{ $t('historical') }}</div>
          </CCardHeader>
          <CCardBody>
            <CChartLine
              :datasets="this.orderChart.datasets"
              :labels="this.orderChart.labels"
              :options="this.orderChart.options"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol col="12" md="7">
        <CCard>
          <CCardHeader>
            <h4 id="drivers" class="card-title mb-0">{{ $t('leasor') }}</h4>
            <div class="small text-muted">{{ $t('in_real_time') }}</div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CCallout color="secondary">
                  <small class="text-muted">{{ $tc('driver', 2) }}</small><br>
                  <strong class="h4">{{ this.nbDriver }}</strong>
                </CCallout>
              </CCol>
              <CCol>
                <CCallout color="primary">
                  <small class="text-muted">{{ $t('online') }}</small><br>
                  <strong class="h4">{{ this.nbDriverAvailable }}</strong>
                </CCallout>
              </CCol>
              <CCol>
                <CCallout color="warning">
                  <small class="text-muted">{{ $t('in_ride') }}</small><br>
                  <strong class="h4">{{ this.nbDriverInService }}</strong>
                </CCallout>
              </CCol>
            </CRow>
            <CDataTable
              class="mb-0 table-outline"
              column-filter
              :items="lessors"
              :fields="lessorsFields"
            >
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import SwitchServiceType from '../components/SwitchServiceType.vue'
import axios from "axios";
import Utils from "../../utils/utils";
import _c from "lodash/collection";
import _m from "lodash/math";

export default {
  name: "Today",
  components: { CChartLine, SwitchServiceType },
  created() {
    this.currentDate = new Date();
    this.currentTime = this.currentDate.toTimeString().split(' ')[0];

    this.updateClientBlock()
    this.updateOrderBlock()
    this.updateLessorBlock()
  },
  data() {
    return {
      askRideNb: 0,
      attributeRideNb: 0,
      successRideNb: 0,
      cancelRideNb: 0,

      askRideNbYesterday: 0,
      attributeRideNbYesterday: 0,
      successRideNbYesterday: 0,
      cancelRideNbYesterday: 0,

      askRideNbLastWeek: 0,
      attributeRideNbLastWeek: 0,
      successRideNbLastWeek: 0,
      cancelRideNbLastWeek: 0,


      estimateFee: 0,
      totalFee: 0,
      realPayAmount: 0,

      estimateFeeYesterday: 0,
      totalFeeYesterday: 0,
      realPayAmountYesterday: 0,

      estimateFeeLastWeek: 0,
      totalFeeLastWeek: 0,
      realPayAmountLastWeek: 0,

      orderChart: {
        datasets: [],
        labels: [],
        options: {
          tooltips: {
            mode: 'index',
            intersect: false
          },
          elements: {
            line: {
              borderWidth: 2,
              fill: false
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        }
      },
      GMVChart: {
        datasets: [],
        labels: [],
        options: {
          tooltips: {
            mode: 'index',
            intersect: false
          },
          elements: {
            line: {
              borderWidth: 2,
              fill: false
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        }
      },

      newCustomersNbToday: 0,
      newCustomersNbYesterday: 0,
      newCustomersNbLastWeek: 0,
      newAccountNbToday: 0,
      newAccountNbYesterday: 0,
      newAccountNbLastWeek: 0,
      lessors: [],

      nbDriver: 0,
      nbDriverAvailable: 0,
      nbDriverInService: 0,

      lessorsFields: [
        { key: 'name', label: this.$t('name'), _style: 'width:150px' },
        { key: 'nbDriver', label: this.$tc('driver', 2), _classes: "text-center", _style: 'width:100px', filter: false },
        { key: 'nbDriverAvailable', label: this.$t('online'), _classes: "text-center", _style: 'width:100px', filter: false },
        { key: 'nbDriverInService', label: this.$t('in_ride'), _classes: "text-center", _style: 'width:100px', filter: false }
      ]
    }
  },
  methods: {

    updateClientBlock() {
      axios.get(`/customers/stats?startDate=${Utils.getNthDateSimple()}&endDate=${Utils.getNthDateSimple()}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newCustomersNbToday = response.data[0].customer_nb
      })

      axios.get(`/customers/stats?startDate=${Utils.getNthDateSimple(-1)}&endDate=${Utils.getNthDateSimple(-1)}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newCustomersNbYesterday = response.data[0].customer_nb
      })

      axios.get(`/customers/stats?startDate=${Utils.getNthDateSimple(-7)}&endDate=${Utils.getNthDateSimple(-7)}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newCustomersNbLastWeek = response.data[0].customer_nb
      })

      axios.get(`/accounts/stats?startDate=${Utils.getNthDateSimple()}&endDate=${Utils.getNthDateSimple()}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newAccountNbToday = response.data[0].account_nb
      })

      axios.get(`/accounts/stats?startDate=${Utils.getNthDateSimple(-1)}&endDate=${Utils.getNthDateSimple(-1)}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newAccountNbYesterday = response.data[0].account_nb
      })

      axios.get(`/accounts/stats?startDate=${Utils.getNthDateSimple(-7)}&endDate=${Utils.getNthDateSimple(-7)}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newAccountNbLastWeek = response.data[0].account_nb
      })
    },

    updateOrderBlock(serviceType) {
      let params = {}

      if (serviceType && serviceType.value !== 0) params.serviceType = serviceType.value

      axios.get(`/orders/stats`, {
        params: {
          startDate: Utils.getNthDateSimple(-8),
          endDate: Utils.getNthDateSimple(0),
          ...params
        }
      }).then((response) => {
        this.updateTodayGMV(response.data)
        this.updateTodayOrders(response.data)
        this.updateOrdersChart(response.data)
        this.updateGMVChart(response.data)
      })

      axios.get(`/orders/stats`,
        {
          params: {
            startDate: Utils.getNthDateSimple(-7),
            endDate: Utils.getNthDateSimple(-7),
            endTime: `T${this.currentTime}`,
            ...params
          }
        }
      ).then((response) => {
        this.updateLastWeekGMV(response.data)
        this.updateLastWeekOrders(response.data)
      })

      axios.get(`/orders/stats`,
        {
          params: {
            startDate: Utils.getNthDateSimple(-1),
            endDate: Utils.getNthDateSimple(-1),
            endTime: `T${this.currentTime}`,
            ...params
          }
        }
      ).then((response) => {
        this.updateYesterdayGMV(response.data)
        this.updateYesterdayOrders(response.data)
      })
    },

    updateLessorBlock() {
      axios.get(`/lessors?withDriverOnlineStatus=true&cityCode=2FR000001`).then((response) => {
        this.lessors = response.data

        this.nbDriver = _m.sumBy(this.lessors, (lessors) => lessors.nbDriver);
        this.nbDriverAvailable = _m.sumBy(this.lessors, (lessors) => lessors.nbDriverAvailable);
        this.nbDriverInService = _m.sumBy(this.lessors, (lessors) => lessors.nbDriverInService);
      })
    },

    updateTodayGMV(data) {
      let today = data[data.length-1];

      this.estimateFee = today.original_fee_sum;
      this.totalFee = today.total_fee_sum;
      this.realPayAmount = today.real_pay_amount_sum;
    },

    updateYesterdayGMV(data) {
      let yesterday = data[data.length-1];

      this.estimateFeeYesterday = yesterday.original_fee_sum;
      this.totalFeeYesterday = yesterday.total_fee_sum;
      this.realPayAmountYesterday = yesterday.real_pay_amount_sum;
    },

    updateLastWeekGMV(data) {
      let lastWeek = data[data.length-1];

      this.estimateFeeLastWeek = lastWeek.original_fee_sum;
      this.totalFeeLastWeek = lastWeek.total_fee_sum;
      this.realPayAmountLastWeek = lastWeek.real_pay_amount_sum;
    },

    updateTodayOrders(data) {
      let today = data[data.length-1];

      this.askRideNb = today["order_nb"];
      this.attributeRideNb = today["attribute_order_nb"];
      this.successRideNb = today["success_order_nb"];
      this.cancelRideNb = today["cancel_order_nb"];
    },

    updateYesterdayOrders(data) {
      let yesterday = data[data.length-1];

      this.askRideNbYesterday = yesterday["order_nb"];
      this.attributeRideNbYesterday = yesterday["attribute_order_nb"];
      this.successRideNbYesterday = yesterday["success_order_nb"];
      this.cancelRideNbYesterday = yesterday["cancel_order_nb"];
    },

    updateLastWeekOrders(data) {
      let lastWeek = data[data.length-1];

      this.askRideNbLastWeek = lastWeek["order_nb"];
      this.attributeRideNbLastWeek = lastWeek["attribute_order_nb"];
      this.successRideNbLastWeek = lastWeek["success_order_nb"];
      this.cancelRideNbLastWeek = lastWeek["cancel_order_nb"];
    },

    updateOrdersChart(data) {
      let filters = this.$options.filters
      this.orderChart.labels = _c.map(data, (day) => filters.getShortDayStringForDate(day["statistics_date"]).capitalize())
      this.orderChart.datasets = [];

      let orderDataset = {
        label: this.$t('asked'),
        lineTension: 0.2,
        borderColor: 'rgba(51,148,245,0.9)',
        data: _c.map(data, (day) => day["order_nb"]),
      }

      let attributeOrderDataset = {
        label: this.$t('attributed'),
        lineTension: 0.2,
        borderColor: 'rgba(239,171,23,0.9)',
        data: _c.map(data, (day) => day["attribute_order_nb"])
      }

      let successOrderDataset = {
        label: this.$t('success'),
        lineTension: 0.2,
        borderColor: 'rgba(46,175,88,0.9)',
        data: _c.map(data, (day) => day["success_order_nb"])
      }

      let cancelOrderDataset = {
        label: this.$t('cancel'),
        lineTension: 0.2,
        borderColor: 'rgba(245,65,54,0.9)',
        data: _c.map(data, (day) => day["cancel_order_nb"]),
      }


      this.orderChart.datasets.push(orderDataset)
      this.orderChart.datasets.push(attributeOrderDataset)
      this.orderChart.datasets.push(successOrderDataset)
      this.orderChart.datasets.push(cancelOrderDataset)
    },

    updateGMVChart(data) {
      let filters = this.$options.filters
      this.GMVChart.labels = _c.map(data, (day) => filters.getShortDayStringForDate(day["statistics_date"]).capitalize())
      this.GMVChart.datasets = [];

      let GMVEstimated = {
        label: this.$t('gmv_estimated'),
        lineTension: 0.2,
        borderColor: 'rgba(51,148,245,0.9)',
        data: _c.map(data, (day) => day["original_fee_sum"]/100),
      }

      let GMVTotal = {
        label: this.$t('gmv_total'),
        lineTension: 0.2,
        borderColor: 'rgba(239,171,23,0.9)',
        data: _c.map(data, (day) => day["total_fee_sum"]/100),
      }

      let GMVReal = {
        label: this.$t('gmv_net'),
        lineTension: 0.2,
        borderColor: 'rgba(46,175,88,0.9)',
        data: _c.map(data, (day) => day["real_pay_amount_sum"]/100),
      }


      this.GMVChart.datasets.push(GMVEstimated)
      this.GMVChart.datasets.push(GMVTotal)
      this.GMVChart.datasets.push(GMVReal)
    }
  }
}
</script>

<style scoped>

</style>
