class Utils {

  static getNthDate(nthDays, fromDate = new Date()) {
    fromDate.setUTCHours(0, 0, 0);
    fromDate.setDate(fromDate.getDate() + nthDays);

    return fromDate;
  }

  static getNthDateSimple(nthDays = 0, fromDate = new Date()) {
    const nthDateISOString = this.getNthDate(nthDays, fromDate).toISOString();
    return nthDateISOString.split('T')[0];
  }
}

export default Utils;
