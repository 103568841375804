<template>
  <div>
    <CRow>
      <CCol col="12" md="6">
        <CCard>
          <CCardHeader>
            <h4 id="rider" class="card-title mb-0">{{ $tc('riders', 2) }}</h4>
            <div class="small text-muted">{{ $t('all_day_long') }}</div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6" md="6" lg="6">
                <CCallout color="success">
                  <small class="text-muted">{{ $t('nb_registration') }}</small><br>
                  <strong class="h4">{{ this.newCustomersNbToday }}</strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.newCustomersNbLastWeek }} -- {{ this.newCustomersNbYesterday }}</strong>
                </CCallout>
              </CCol>
              <CCol sm="6" md="6" lg="6">
                <CCallout color="success">
                  <small class="text-muted">{{ $t('nb_registration_with_credit_card') }}</small><br>
                  <strong class="h4">{{ this.newAccountNbToday }}</strong><br>
                  <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}</small><br>
                  <strong class="h6">{{ this.newAccountNbLastWeek }} -- {{ this.newAccountNbYesterday }}</strong>
                </CCallout>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>

        <CCOrderAmount
          :biz="12"
        />
      </CCol>
      <CCol col="12" md="6">

        <CCLaboursDriversOnlineStatus/>

        <CCOrderNumber
          :biz="12"
        />
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="6" md="6" lg="6">
        <CCDriversCancelNumber/>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CCOrderNumber from "../../components/cards/CCOrderNumber.vue";
import CCOrderAmount from '../../components/cards/CCOrderAmount.vue'
import CCLaboursDriversOnlineStatus from '../../components/cards/CCLaboursDriversOnlineStatus.vue'
import CCDriversCancelNumber from "../../components/cards/CCDriversCancelNumber.vue";
import axios from "axios";
import Utils from "../../../utils/utils";

export default {
  name: "B2C",
  components: { CCOrderAmount, CCOrderNumber, CCLaboursDriversOnlineStatus, CCDriversCancelNumber },
  created() {
    this.currentDate = new Date();
    this.currentTime = this.currentDate.toTimeString().split(' ')[0];

    this.updateClientBlock()
  },
  data() {
    return {
      newCustomersNbToday: 0,
      newCustomersNbYesterday: 0,
      newCustomersNbLastWeek: 0,
      newAccountNbToday: 0,
      newAccountNbYesterday: 0,
      newAccountNbLastWeek: 0,

      orderCanceledByDriver: [],
      orderCanceledByDriverFields: [
        { key: 'fullname', label: this.$t('name'), _style: 'width:150px' },
        { key: 'cancel_nb', label: this.$tc('cancel_nb', 2), _classes: "text-center", _style: 'width:100px', filter: false }
      ]
    }
  },
  methods: {
    updateClientBlock() {
      axios.get(`/customers/history?startDate=${Utils.getNthDateSimple(-7)}&endDate=${Utils.getNthDateSimple()}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newCustomersNbToday = response.data[response.data.length - 1].customer_nb
        this.newCustomersNbYesterday = response.data[response.data.length - 2].customer_nb
        this.newCustomersNbLastWeek = response.data[0].customer_nb
      })

      axios.get(`/accounts/history?startDate=${Utils.getNthDateSimple(-7)}&endDate=${Utils.getNthDateSimple()}&endTime=T${(this.currentTime)}`).then((response) => {
        this.newAccountNbToday = response.data[response.data.length - 1].account_nb ?? response.data[response.data.length - 1].account_nb
        this.newAccountNbYesterday = response.data[response.data.length - 2].account_nb ?? response.data[response.data.length - 2].account_nb
        this.newAccountNbLastWeek = response.data[0].account_nb ?? response.data[0].account_nb
      })

      axios.get(`/drivers/orderCancelHistory?startDate=${Utils.getNthDateSimple()}&endDate=${Utils.getNthDateSimple(1)}`).then((response) => {
        this.orderCanceledByDriver = response.data ?? response.data;
      })
    },

    driverRowClicked(driver) {
      this.$router.push(`/operation/subcontractors/${driver.labour_no}/drivers/${driver.driver_no}`);
    },
  }
}
</script>

<style scoped>

</style>
