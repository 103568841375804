<template>
  <div>
    <CRow>
      <CCol sm="6" md="2">
        <CWidgetProgressIcon
          :header="this.customer.success_customer_nb | formatNumber"
          text="Clients (min 1 course)"
          inverse
          color="gradient-info"
          :value="0"
        >
          <CIcon name="cil-people" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" md="1"></CCol>
      <CCol sm="6" md="2"></CCol>
      <CCol sm="6" md="1"></CCol>
      <CCol sm="6" md="2">
        <CWidgetProgressIcon
          :header="this.comfort.success_order_nb + this.new_energy.success_order_nb | formatNumber"
          text="Courses faites"
          inverse
          color="gradient-dark"
          :value="0"
        >
          <CIcon name="cil-car-alt" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
      <CCol sm="6" md="1"></CCol>
      <CCol sm="6" md="2">
        <CWidgetProgressIcon
          :header="this.comfort.total_fee_sum + this.new_energy.total_fee_sum | convertCentsToEurosWithCurrency"
          text="Total facturé"
          inverse
          color="gradient-danger"
          :value="0"
        >
          <CIcon name="cil-money" height="36"/>
        </CWidgetProgressIcon>
      </CCol>
    </CRow>

    <CRow>
      <CCol col="12" md="6">
        <CCard>
          <CCardBody>
            <h4 id="rider" class="card-title mb-0">Clients</h4>
            <div class="small text-muted">{{ $t('from_beginning') }}</div>
            <CRow>
              <CCol col="12" md="6">
                <CCallout color="info">
                  <small class="text-muted">Nombre d'inscrits en France</small><br>
                  <strong class="h4">{{ this.customer.france_customer_nb | formatNumber }}</strong>
                </CCallout>
                <CCallout color="info">
                  <small class="text-muted">Nombre d'inscrits en France (without delete or test)</small><br>
                  <strong class="h4">{{ this.customer.france_active_customer_nb | formatNumber }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="6">
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCLaboursDriversOnlineStatus
          :show-list=true
        />
      </CCol>
      <CCol col="12" md="6">
        <CCard>
          <CCardBody>
            <h4 class="card-title mb-0">{{ $tc('ride', 2) }} TX</h4>
            <div class="small text-muted">{{ $t('from_beginning') }}</div>
            <CRow>
              <CCol col="12" md="6">
                <CCallout color="dark">
                  <small class="text-muted">Nombre de courses faites</small><br>
                  <strong class="h4">{{ this.comfort.success_order_nb | formatNumber }}</strong>
                </CCallout>
                <CCallout color="dark">
                  <small class="text-muted">Nombre de courses demandées</small><br>
                  <strong class="h4">{{ this.comfort.order_nb | formatNumber }}</strong>
                </CCallout>
                <CCallout color="dark">
                  <small class="text-muted">Nombre de courses annulées</small><br>
                  <strong class="h4">{{ this.comfort.cancel_order_nb | formatNumber }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="6">
                <CCallout color="danger">
                  <small class="text-muted">Total facturé<sup>1</sup></small><br>
                  <strong class="h4">{{ this.comfort.total_fee_sum | convertCentsToEurosWithCurrency }}</strong>
                </CCallout>
                <CCallout color="danger">
                  <small class="text-muted">Total facturé moins les coupons</small><br>
                  <strong class="h4">{{ this.comfort.real_pay_amount_sum | convertCentsToEurosWithCurrency }}</strong>
                </CCallout>
                <CCallout color="danger">
                  <small class="text-muted">Prix moyen d'une course moins les coupons</small><br>
                  <strong class="h4">{{ this.comfort.success_order_paid_avg | convertCentsToEurosWithCurrency }}</strong>
                </CCallout>
                <p>1. Sans les annulations</p>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <h4 class="card-title mb-0">{{ $tc('ride', 2) }} Atmo</h4>
            <div class="small text-muted">{{ $t('from_beginning') }}</div>
            <CRow>
              <CCol col="12" md="6">
                <CCallout color="dark">
                  <small class="text-muted">Nombre de courses faites</small><br>
                  <strong class="h4">{{ this.new_energy.success_order_nb | formatNumber }}</strong>
                </CCallout>
                <CCallout color="dark">
                  <small class="text-muted">Nombre de courses demandées</small><br>
                  <strong class="h4">{{ this.new_energy.order_nb | formatNumber }}</strong>
                </CCallout>
                <CCallout color="dark">
                  <small class="text-muted">Nombre de courses annulées</small><br>
                  <strong class="h4">{{ this.new_energy.cancel_order_nb | formatNumber }}</strong>
                </CCallout>
              </CCol>
              <CCol col="12" md="6">
                <CCallout color="danger">
                  <small class="text-muted">Total facturé<sup>1</sup></small><br>
                  <strong class="h4">{{ this.new_energy.total_fee_sum | convertCentsToEurosWithCurrency }}</strong>
                </CCallout>
                <CCallout color="danger">
                  <small class="text-muted">Total facturé moins les coupons</small><br>
                  <strong class="h4">{{ this.new_energy.real_pay_amount_sum | convertCentsToEurosWithCurrency }}</strong>
                </CCallout>
                <CCallout color="danger">
                  <small class="text-muted">Prix moyen d'une course moins les coupons</small><br>
                  <strong class="h4">{{ this.new_energy.success_order_paid_avg | convertCentsToEurosWithCurrency }}</strong>
                </CCallout>
                <p>1. Sans les annulations</p>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from "axios";
import CCLaboursDriversOnlineStatus from '../components/cards/CCLaboursDriversOnlineStatus.vue'
import { OrderEnum } from '@caocao-mobility/ccmp-enum';

export default {
  name: "Global",
  components: { CCLaboursDriversOnlineStatus },

  data() {
    return {
      isLoading: true,
      customer: {
        france_customer_nb: 0,
        france_active_customer_nb: 0,
        success_customer_nb: 0
      },

      new_energy: {
        order_nb: 0,
        success_order_nb:	0,
        total_fee_sum:	0,
        real_pay_amount_sum:	0,
        success_order_billed_avg:	0,
        success_order_paid_avg:	0,
        cancel_order_nb: 0
      },

      comfort: {
        order_nb: 0,
        success_order_nb:	0,
        total_fee_sum:	0,
        real_pay_amount_sum:	0,
        success_order_billed_avg:	0,
        success_order_paid_avg:	0,
        cancel_order_nb: 0
      }
    }
  },

  created() {
    this.updateCustomerStats();
    this.updateOrderStats();
  },

  methods: {
    updateCustomerStats () {
      axios.get(`/customers/stats?cityCode=2FR000001`).then((response) => {
        this.isLoading = false

        this.customer.success_customer_nb = response.data[0].success_customer_nb ?? response.data[0].success_customer_nb;
      })

      axios.get(`/customers/history?cityCode=2FR000001`).then((response) => {
        this.isLoading = false

        this.customer.france_customer_nb = response.data[0].customer_nb ?? response.data[0].customer_nb;
        this.customer.france_active_customer_nb = response.data[0].active_customer_nb ?? response.data[0].active_customer_nb;
      })
    },

    updateOrderStats () {
      axios.get(`/orders/history`, {
        params: {
          serviceType: OrderEnum.ServiceType.NEW_ENERGY,
        }
      }).then((response) => {
        this.isLoading = false

        response.data = response.data[0];

        this.new_energy.order_nb = response.data.order_nb ?? response.data.order_nb;
        this.new_energy.success_order_nb = response.data.success_order_nb ?? response.data.success_order_nb;
        this.new_energy.total_fee_sum = response.data.total_fee_sum ?? response.data.total_fee_sum;
        this.new_energy.real_pay_amount_sum = response.data.real_pay_amount_sum ?? response.data.real_pay_amount_sum;
        this.new_energy.success_order_billed_avg = response.data.success_order_billed_avg ?? response.data.success_order_billed_avg;
        this.new_energy.success_order_paid_avg = response.data.success_order_paid_avg ?? response.data.success_order_paid_avg;
        this.new_energy.cancel_order_nb = response.data.cancel_order_nb ?? response.data.cancel_order_nb;
      })

      axios.get(`/orders/history`,{
        params: {
          serviceType: OrderEnum.ServiceType.COMFORT,
        }
      }).then((response) => {
        this.isLoading = false

        response.data = response.data[0];

        this.comfort.order_nb = response.data.order_nb ?? response.data.order_nb;
        this.comfort.success_order_nb = response.data.success_order_nb ?? response.data.success_order_nb;
        this.comfort.total_fee_sum = response.data.total_fee_sum ?? response.data.total_fee_sum;
        this.comfort.real_pay_amount_sum = response.data.real_pay_amount_sum ?? response.data.real_pay_amount_sum;
        this.comfort.success_order_billed_avg = response.data.success_order_billed_avg ?? response.data.success_order_billed_avg;
        this.comfort.success_order_paid_avg = response.data.success_order_paid_avg ?? response.data.success_order_paid_avg;
        this.comfort.cancel_order_nb = response.data.cancel_order_nb ?? response.data.cancel_order_nb;
      })
    },
  }
}
</script>

<style scoped>

</style>
