<template>
  <CCard>
    <CCardHeader>
      <h4 id="drivers" class="card-title mb-0">{{ $t('leasor') }}</h4>
      <div class="small text-muted">{{ $t('in_real_time') }}</div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol>
          <CCallout color="secondary">
            <small class="text-muted">{{ $tc('driver', 2) }}</small><br>
            <strong class="h4">{{ this.nbDriver }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="primary">
            <small class="text-muted">{{ $t('online') }}</small><br>
            <strong class="h4">{{ this.nbDriverAvailable }}</strong>
          </CCallout>
        </CCol>
        <CCol>
          <CCallout color="warning">
            <small class="text-muted">{{ $t('in_ride') }}</small><br>
            <strong class="h4">{{ this.nbDriverInService }}</strong>
          </CCallout>
        </CCol>
      </CRow>
      <CDataTable
        v-if="showList"
        class="mb-0 table-outline"
        column-filter
        :items="lessors"
        :fields="lessorsFields"
      >
      </CDataTable>
    </CCardBody>

    <slot name="loading" v-if="isLoading">
      <CElementCover
        :boundaries="[
            { sides: ['top', 'bottom'] }
          ]"
      />
    </slot>
  </CCard>
</template>

<script>
import axios from "axios";
import _m from "lodash/math";

export default {
  name: "CCLaboursDriversOnlineStatus",

  props: {
    showList: {
      default: false,
      type: Boolean
    },
  },

  data() {
    return {
      isLoading: false,

      lessors: [],

      nbDriver: 0,
      nbDriverAvailable: 0,
      nbDriverInService: 0,

      lessorsFields: [
        { key: 'name', label: this.$t('name'), _style: 'width:150px' },
        { key: 'nbDriver', label: this.$tc('driver', 2), _classes: "text-center", _style: 'width:100px', filter: false },
        { key: 'nbDriverAvailable', label: this.$t('online'), _classes: "text-center", _style: 'width:100px', filter: false },
        { key: 'nbDriverInService', label: this.$t('in_ride'), _classes: "text-center", _style: 'width:100px', filter: false }
      ]
    }
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.isLoading = true

      axios.get(`/lessors?withDriverOnlineStatus=true&cityCode=2FR000001`).then((response) => {
        this.isLoading = false;

        this.lessors = response.data;

        this.nbDriver = _m.sumBy(this.lessors, (lessors) => lessors.nbDriver);
        this.nbDriverAvailable = _m.sumBy(this.lessors, (lessors) => lessors.nbDriverAvailable);
        this.nbDriverInService = _m.sumBy(this.lessors, (lessors) => lessors.nbDriverInService);
      })
    },
  }
}
</script>

<style scoped>

</style>
