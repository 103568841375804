<template>
  <CCard>
    <CCardHeader>
      <CRow>
        <CCol sm="5">
          <h4 id="gmv" class="card-title mb-0">GMV</h4>
          <div class="small text-muted">{{ $t('all_day_long') }}</div>
        </CCol>
        <CCol sm="7">
          <SwitchServiceType
            v-on:change="getData"
          />
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6" md="6" lg="6">
          <CCallout color="info">
            <small class="text-muted">{{ $t('estimated_amount') }}<sup>1</sup></small><br>
            <strong class="h4">{{ this.today.total_fee_sum | convertCentsToEurosWithCurrency }} </strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
            <strong class="h6">{{ this.lastWeek.total_fee_sum | convertCentsToEurosWithCurrency }} -- {{ this.yesterday.total_fee_sum | convertCentsToEurosWithCurrency }}</strong>
          </CCallout>
          <CCallout color="warning">
            <small class="text-muted">{{ $t('billed_amount') }}<sup>1</sup></small><br>
            <strong class="h4">{{ this.today.real_pay_amount_sum | convertCentsToEurosWithCurrency }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
            <strong class="h6">{{ this.lastWeek.real_pay_amount_sum | convertCentsToEurosWithCurrency }} -- {{ this.yesterday.real_pay_amount_sum | convertCentsToEurosWithCurrency }}</strong>
          </CCallout>
        </CCol>

        <CCol sm="6" md="6" lg="6">
          <CCallout color="success">
            <small class="text-muted">{{ $t('commission_amount') }}<sup>1</sup></small><br>
            <strong class="h4">{{ this.today.platform_split_amount_sum | convertCentsToEurosWithCurrency }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
            <strong class="h6">{{ this.lastWeek.platform_split_amount_sum | convertCentsToEurosWithCurrency }} -- {{ this.yesterday.platform_split_amount_sum | convertCentsToEurosWithCurrency }}</strong>
          </CCallout>
          <CCallout color="dark">
            <small class="text-muted">{{ $t('subcontractor_amount') }}<sup>1</sup></small><br>
            <strong class="h4">{{ this.today.labour_split_amount_sum | convertCentsToEurosWithCurrency }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
            <strong class="h6">{{ this.lastWeek.labour_split_amount_sum | convertCentsToEurosWithCurrency }} -- {{ this.yesterday.labour_split_amount_sum | convertCentsToEurosWithCurrency }}</strong>
          </CCallout>
          <CCallout color="pink">
            <small class="text-muted">{{ $t('coupon_amount') }}<sup>1</sup></small><br>
            <strong class="h4">{{ this.today.coupon_real_amount_sum | convertCentsToEurosWithCurrency }}</strong><br>
            <small class="text-muted">{{ $t('last_week') }} / {{ $t('yesterday') }}<sup>2</sup></small><br>
            <strong class="h6">{{ this.lastWeek.coupon_real_amount_sum | convertCentsToEurosWithCurrency }} -- {{ this.yesterday.coupon_real_amount_sum | convertCentsToEurosWithCurrency }}</strong>
          </CCallout>
        </CCol>
      </CRow>

      <p>1 - {{ $t('on_cancelled_and_completed_rides') }}</p>
      <p>2 - {{ $t('at_the_same_time_for_yesterday_or_lastweek') }}</p>

      <CChartLine
        :datasets="this.chart.datasets"
        :labels="this.chart.labels"
        :options="this.chart.options"
      />
    </CCardBody>

    <slot name="loading" v-if="isLoading">
      <CElementCover
        :boundaries="[
            { sides: ['top', 'bottom'] }
          ]"
      />
    </slot>
  </CCard>
</template>

<script>
import {CChartLine} from "@coreui/vue-chartjs";
import SwitchServiceType from "@/views/components/SwitchServiceType";
import _c from "lodash/collection";
import axios from "axios";
import Utils from "@/utils/utils";

export default {
  name: "CCOrderAmount",
  props: {
    biz: {
      type: Number
    },
    serviceType: {
      type: Number
    }
  },
  components: { CChartLine, SwitchServiceType },
  data() {
    return {
      isLoading: false,

      data: [],
      today: {},
      yesterday: {},
      lastWeek: {},
      chart: {
        datasets: [],
        labels: [],
        options: {
          tooltips: {
            mode: 'index',
            intersect: false
          },
          elements: {
            line: {
              borderWidth: 2,
              fill: false
            },
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4
            }
          }
        }
      },
    }
  },
  created() {
    this.currentDate = new Date();
    this.currentTime = this.currentDate.toTimeString().split(' ')[0];

    this.getData()
  },
  methods: {
    getData(serviceType) {
      this.isLoading = true

      let params = {}

      if (this.biz) params.biz = this.biz;
      if (serviceType && serviceType.value !== 0) params.serviceType = serviceType.value;
      if (this.serviceType && this.serviceType !== 0) params.serviceType = this.serviceType;

      axios.get(`/orders/history`, {
        params: {
          startDate: Utils.getNthDateSimple(-8),
          endDate: Utils.getNthDateSimple(0),
          endTime: `T${this.currentTime}`,
          ...params
        }
      }).then((response) => {
        this.isLoading = false

        const data = response.data;

        if (data.length) {
          this.today = data[data.length-1] ?? data[data.length-1];
          this.yesterday = data[data.length-2] ?? data[data.length-2];
          this.lastWeek = data[1] ?? data[1];
        }
      })

      axios.get(`/orders/history`, {
        params: {
          startDate: Utils.getNthDateSimple(-8),
          endDate: Utils.getNthDateSimple(0),
          ...params
        }
      }).then((response) => {
        this.updateChart(response.data);
      })
    },

    updateChart(data) {
      let filters = this.$options.filters
      this.chart.labels = _c.map(data, (day) => filters.getShortDayStringForDate(day["statistics_date"]).capitalize())
      this.chart.datasets = [];

      let estimatedAmount = {
        label: this.$t('estimated_amount'),
        lineTension: 0.2,
        borderColor: 'rgba(51,148,245,0.9)',
        data: _c.map(data, (day) => day["total_fee_sum"]/100),
      }

      let billedAmount = {
        label: this.$t('billed_amount'),
        lineTension: 0.2,
        borderColor: 'rgba(239,171,23,0.9)',
        data: _c.map(data, (day) => (day["real_pay_amount_sum"]) /100),
      }

      let commissionAmount = {
        label: this.$t('commission_amount'),
        lineTension: 0.2,
        borderColor: 'rgb(46,184,92)',
        data: _c.map(data, (day) => day["platform_split_amount_sum"]/100),
      }

      let subcontractorAmount = {
        label: this.$t('subcontractor_amount'),
        lineTension: 0.2,
        borderColor: 'rgb(98,109,128)',
        data: _c.map(data, (day) => day["labour_split_amount_sum"]/100),
      }

      let couponAmount = {
        label: this.$t('coupon_amount'),
        lineTension: 0.2,
        borderColor: 'rgb(200,19,134)',
        data: _c.map(data, (day) => day["coupon_real_amount_sum"]/100),
      }

      this.chart.datasets.push(estimatedAmount)
      this.chart.datasets.push(billedAmount)

      this.chart.datasets.push(commissionAmount)
      this.chart.datasets.push(subcontractorAmount)
      this.chart.datasets.push(couponAmount)
    },
  }
}
</script>

<style scoped>

</style>
